import React from 'react'
import img1 from './img/img1.png'
import img2 from './img/img2.png'
import img3 from './img/img3.png'
import img4 from './img/img4.png'
import img5 from './img/img5.png'
import img6 from './img/img6.png'
function HomeOurMission() {
  return (
    <>

      <div className="homeourmissioncontainer">

        <div className="homeourmissionbox1">
          <h1>Our Mission</h1>
          <p>Our mission at Maa Gayatri Jan Sewa Sansthan is to serve and empower the most vulnerable sections of society, with a particular focus on women, children, and the elderly. We are committed to providing access to basic necessities, education, and skill development opportunities, and promoting awareness and advocacy for issues that affect these communities.</p>
        </div>

        <div className="homeourmissionboxs">
          <div className="homeourmissionbox">
            <div className="homeourmissionimg">
              <img src={img1} alt="Charity For Education ngo" />
            </div>
            <h1>Charity For Education</h1>
            <p>This is a short description elaborating the service you have mentioned above.​</p>
          </div>
          <div className="homeourmissionbox">
            <div className="homeourmissionimg">
              <img src={img2} alt="Feed For Hungry ngo" />
            </div>
            <h1>Feed For Hungry</h1>
            <p>This is a short description elaborating the service you have mentioned above.​</p>
          </div>
          <div className="homeourmissionbox">
            <div className="homeourmissionimg">
              <img src={img3} alt="Filtered Drinking Water ngo" />
            </div>
            <h1>Filtered Drinking Water</h1>
            <p>This is a short description elaborating the service you have mentioned above.​</p>
          </div>
          <div className="homeourmissionbox">
            <div className="homeourmissionimg">
              <img src={img4} alt="Medical Facilities ngo" />
            </div>
            <h1>Medical Facilities</h1>
            <p>This is a short description elaborating the service you have mentioned above.​</p>
          </div>
          <div className="homeourmissionbox">
            <div className="homeourmissionimg">
              <img src={img5} alt="Nutritious Food ngo" />
            </div>
            <h1>Nutritious Food</h1>
            <p>This is a short description elaborating the service you have mentioned above.​</p>
          </div>
          <div className="homeourmissionbox">
            <div className="homeourmissionimg">
              <img src={img6} alt="Innovation For Children ngo" />
            </div>
            <h1>Innovation For Children</h1>
            <p>This is a short description elaborating the service you have mentioned above.​</p>
          </div>
        </div>

      </div>

    </>
  )
}

export default HomeOurMission
