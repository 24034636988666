import React, { useRef, useState } from 'react'
import con3 from './img/con5.jpg'
// import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import con4 from './img/con4.jpg'
// import HomeOurMission from './HomeOurMission'
import Donation from './Donation'
import Payment from './Payment';
import { Helmet } from 'react-helmet';
function Contact() {



  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_btl4bwc', 'template_xxg9iro', form.current, 'd6z2h8cZXEkFfm1SV')
      .then((result) => {
          console.log(result.text);
          alert('message send')
          let inputs =document.querySelectorAll('input');
    let text =document.querySelectorAll('textarea');

    inputs.forEach(input=>input.value="");
    text.forEach(input=>input.value="");

      }, (error) => {
          console.log(error.text);
      });
    }





  const [input, setInput] = useState({
    name:"",
    email:"",
    message:""

  })
  console.log(input)

  const takedata = (e)=>{
        const {name,value} = e.target;
        setInput({...input,[name]:value})
  }


  const inputdata = async (e)=>{

    console.log("button click")

    e.preventDefault();
    const { name, email,message} = input;
    const res = await fetch('/register',{
      method:'POST',
      headers:{
        "content-Type" : "application/json"
      },
      body:JSON.stringify({
        name, email,message
      })
    });
    let inputs =document.querySelectorAll('input');
    let text =document.querySelectorAll('textarea');

    inputs.forEach(input=>input.value="");
    text.forEach(input=>input.value="");
    
    const data = await res.json();
    if(data.status === 404 || !data){
      alert("invalid Registration");
      console.log("invalid Registration");
    }else{
      alert("Message Send Successfully");
      console.log("Registration Successfull");
      
    }
    


  }


  const [showModal, setModel] = useState(false);

  const closeModel = ()=>setModel(false);



  return (
    <>

    <Helmet>
    <title>Reach Out to Us: Contact MGJSS NGO for Support, Collaboration, and Making a Difference</title>
    <meta
      name="description"
      content="Get in touch with Maa Gayatri Jan Seva NGO through our contact page. Reach out to us for inquiries, collaborations, and support."

    />
    <link rel="canonical" href="/about" />
  </Helmet>
    


      <div className="contactcontainer">
      
            <div className="aboutuppersection">
            <div className="pageupperheading1">
              <h1>Contact Us</h1>
            </div>
            
            <div className="aboutuppersec1boxs">
            <div className="aboutuppersec1box">
            <div className="aboutimgbox3">
            
            <img src={con3} alt="Maa Gayatri contact " />
            </div>
          
            
            </div>
            <div className="aboutuppersec1box">
          <h1>"Alone we can do so little together we can do so much" <br /> <br /> "we`re all in this together." </h1>
          <br />
          <br />
          <div className="homebutton2">
          <button onClick={()=>setModel(true)}>Donate Now</button>
          {showModal && <Payment close ={closeModel}/>}
          </div>
            </div>
            <div className="aboutuppersec1box">
            <div className="aboutimgbox3">
            
            <img src={con4} alt="Maa Gayatri contact page" />
            </div> 
            </div>
            
            </div>

            </div>

      <div id="contactformcontainer">
      
      <div className="contactbox">
      <h1>Contact Detail</h1>
    <p>  <b>Address:</b> Office- 69 Kshatriya Bhavan Near <br /> Amul Dairy (HCL IT City), Madharmau Kala, Sultanpur <br /> Road, Lucknow​</p>
    
    <p> <b> email: </b>  maagayatrijansevasansthan@gmail.com</p>
    
    <p> <b> Land Line: </b>  0522-4009119</p>
    <p> <b> Mob No: </b>  8840234036, <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  9918112969, <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9305206789</p>
    
    <h2>Follow Us</h2>

    <div className="contacelomks">
    
    
    <a href="https://www.facebook.com/profile.php?id=100066859436457"> <i class="fa-brands fa-square-facebook"></i></a>        
    <a href="/"> <i class="fa-brands fa-square-instagram"></i></a>
    <a href="/"> <i class="fa-brands fa-square-twitter"></i> </a> 
    <a href="/"> <i class="fa-brands fa-square-youtube"></i></a>
    
    </div>
    </div>

    <div className="contactbox1">
    <h1>Send Us A Message</h1>
    <form ref={form} onSubmit={sendEmail}  >
    <div className="forminput">
    <input type="text" placeholder='Your Name' name='name' value={input.name}  onChange={takedata} />
    </div>
    <div className="forminput">
    <input type="email" placeholder='Your Email' name='email' value={input.email}  onChange={takedata} />
    </div>
    <div className="formtextarea">
    <textarea name="message" id=""  cols="30" rows="10" placeholder='Your Message' value={input.message} onChange={takedata} ></textarea>
    </div>
    <div className="formbutton">
    <button type='submit' value="Send" >Send Your Message</button>
    </div>
    
    </form>
    </div>
    <div className="contactbox">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8782.74510932807!2d81.0204691215911!3d26.7840495291588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be46386b7bb9b%3A0x54f837d51c21de2b!2sMarhar%20Mau%20Khurd%2C%20Uttar%20Pradesh%20226002!5e0!3m2!1sen!2sin!4v1683376222181!5m2!1sen!2sin" width="400" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
      
      </div>


      <Donation/>
      
      </div>
    </>
  )
}

export default Contact
