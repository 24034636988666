import React, { useState } from 'react'

import cu1 from './img/cu1.jpg'
import cu2 from './img/cu2.jpg'
import cu3 from './img/cu3.jpg'
import cu4 from './img/cu4.jpg'
import cu5 from './img/cu5.jpg'
import cu6 from './img/cu6.jpg'
import me1 from './img/me1.jpg'
import me2 from './img/me2.jpg'
import me3 from './img/me3.jpg'
import me4 from './img/me4.jpg'
import me5 from './img/me5.jpg'
import me6 from './img/me6.jpg'

import sos1 from './img/sos1.jpg'
import sos2 from './img/sos2.jpg'
import sos3 from './img/sos3.jpg'
import sos4 from './img/sos4.jpg'
import sos5 from './img/sos5.jpg'
import sos6 from './img/sos6.jpg'
import sos7 from './img/sos7.jpg'
import sos8 from './img/sos8.jpg'
import sos9 from './img/sos9.jpg'
import ga1 from './img/ga1.jpg'
import ga2 from './img/ga2.jpg'
import { NavLink } from 'react-router-dom'
import Payment from './Payment'
import { Helmet } from 'react-helmet'
function Gallery() {


    let data = [

        {
            id: 1,
            imgSrc: me1
        },
        {
            id: 2,
            imgSrc: me2
        },
        {
            id: 3,
            imgSrc: me3
        },
        {
            id: 3,
            imgSrc: me4
        },
        {
            id: 3,
            imgSrc: me5
        },
        {
            id: 3,
            imgSrc: me6
        },


    ]
    let data1 = [

        {
            id: 1,
            imgSrc: cu1
        },
        {
            id: 2,
            imgSrc: cu2
        },
        {
            id: 3,
            imgSrc: cu3
        },
        {
            id: 3,
            imgSrc: cu4
        },
        {
            id: 3,
            imgSrc: cu5
        },
        {
            id: 3,
            imgSrc: cu6
        },


    ]
    let data2 = [

        {
            id: 1,
            imgSrc: sos1
        },
        {
            id: 2,
            imgSrc: sos2
        },
        {
            id: 3,
            imgSrc: sos3
        },
        {
            id: 3,
            imgSrc: sos4
        },
        {
            id: 3,
            imgSrc: sos5
        },
        {
            id: 3,
            imgSrc: sos6
        },
        {
            id: 3,
            imgSrc: sos7
        },
        {
            id: 3,
            imgSrc: sos8
        },
        {
            id: 3,
            imgSrc: sos9
        },


    ]


    const [model, setmodel] = useState(false);
    const [tempimgsrc, setTempImgSrc] = useState('');

    const getImg = (imgSrc) => {

        setTempImgSrc(imgSrc);
        setmodel(true);

    }



    const [showModal, setModel] = useState(false);

    const closeModel = () => setModel(false);


    return (
        <>

            <Helmet>
                <title>Capturing Moments of Impact: MGJSS NGO`s Inspiring Journey in Photos</title>
                <meta
                    name="description"
                    content="Experience the visual journey of Maa Gayatri Jan Seva NGO through our captivating gallery. Witness the impact we create in communities."

                />
                <link rel="canonical" href="/about" />
            </Helmet>

            <div className={model ? "model open" : "model"}>

                <div className="modelimgcontainer">
                    <img src={tempimgsrc} alt="" />

                    <h1 onClick={() => setmodel(false)} ><i class="zmdi zmdi-close"></i></h1>
                </div>




            </div>

            <div className="gallarycontainer">



                <div className="aboutuppersection">

                    <div className="pageupperheading1">
                        <h1>Our Gallery</h1>
                    </div>

                    <div className="aboutuppersec1boxs">
                        <div className="aboutuppersec1box">
                            <div className="aboutimgbox3">

                                <img src={ga1} alt="Maa gayatri jan sakti ngo" />
                            </div>


                        </div>
                        <div className="aboutuppersec1box">
                            <h1>" The best way to find your self is to lose yourself in the service of others" <br /> <br /> "Services to other is the rent you pay for your room here on earth" </h1>
                            <br />

                            <div className="homebutton2">
                                <button onClick={() => setModel(true)}>Donate Now</button>
                                {showModal && <Payment close={closeModel} />}
                            </div>
                        </div>
                        <div className="aboutuppersec1box">
                            <div className="aboutimgbox3">

                                <img src={ga2} alt="Prostheses for the disables " />
                            </div>
                        </div>

                    </div>

                </div>
                <div className="gallarysection2">
                    <h1>Video clips</h1>
                    <hr />
                    <div className="videoclipcontainer">
                        <div className="videoclip1">

                            <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2F100066859436457%2Fvideos%2F159598126381459%2F&show_text=false&width=267&t=0" width="550" height="350" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                        <div className="videoclip1">


                            <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2F100066859436457%2Fvideos%2F1484581521897494%2F&show_text=false&width=267&t=0" width="550" height="350" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>

                        </div>

                    </div>
                </div>


                <div className="gallarysection3">
                    <h1>Media Coverage</h1>
                    <hr />

                    <div className="gallarycontainer1">
                        <div className="gallarybox12">
                            {data.map((item, index) => {
                                return (
                                    <div className="piccontainer" key={index} onClick={() => getImg(item.imgSrc)}>
                                        <img src={item.imgSrc} alt="" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                  

                </div>
                <div className="gallarysection3">
                    <h1>Cultural Events</h1>
                    <hr />

                    <div className="gallarycontainer1">
                        <div className="gallarybox12">
                            {data1.map((item, index) => {
                                return (
                                    <div className="piccontainer" key={index} onClick={() => getImg(item.imgSrc)}>
                                        <img src={item.imgSrc} alt="" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>



                </div>

                <div className="gallarysection3">
                    <h1>Social work</h1>

                    <hr />

                    <div className="gallarycontainer1">
                    <div className="gallarybox12">
                        {data2.map((item, index) => {
                            return (
                                <div className="piccontainer" key={index} onClick={() => getImg(item.imgSrc)}>
                                    <img src={item.imgSrc} alt="" />
                                </div>
                            )
                        })}
                    </div>
                </div>

                </div>

            </div >

        </>
    )
}

export default Gallery
