import React from 'react'
import logo from './img/logo.jpg'
function Footer() {

  return (
    <>
      <div className="footercontainer">

        <div className="footerboxs">
          <div className="footerbox">
            <div className="footerlogo">
              <img src={logo} alt="Maa Gayatri logo" />
            </div>
            <p>You can use a few enticing words and flaunt your capabilities that will attract future donors and encourage them to donate right away.</p>


          </div>
          <div className="footerbox">
            <h1>Quick Links</h1>
            <p> <a href="/">Home</a></p>
            <p ><a href="/about"> About </a></p>
            <p><a href="/causes"> Our Causes</a></p>
            <p><a href="/team"> team</a></p>
            <p><a href="/contact"> Contact Us</a></p>

          </div>
          <div className="footerbox">
            <h1>Contact Info</h1>
            <p><b>Address:- </b> Office- 69 Kshatriya Bhavan Near
              Amul Dairy (HCL IT City), Madharmau Kala, Sultanpur
              Road, Lucknow​</p>
            <p><b>Phone:-</b>8840234036,
              9918112969,
              9305206789 </p>
            <div className="footeremailpara">
              <p><b>Email:- </b>maagayatrijansevasansthan@gmail.com</p>
            </div>


          </div>
          <div className="footerbox">
            <h1>Keep In Touch</h1>
            <p>Keep Donating</p>
            <div className="navlogolinks">
              <a href="https://www.facebook.com/profile.php?id=100066859436457"> <i class="fa-brands fa-square-facebook"></i></a>
              <a href="/"> <i class="fa-brands fa-square-instagram"></i></a>
              <a href="/"> <i class="fa-brands fa-square-twitter"></i> </a>
              <a href="/"> <i class="fa-brands fa-square-youtube"></i></a>
            </div>

          </div>
        </div>

      </div>

    </>
  )
}

export default Footer
