import React from 'react'

import me1 from './img/me1.jpg'
import me2 from './img/me2.jpg'
import me3 from './img/me3.jpg'
import me4 from './img/me4.jpg'
import me5 from './img/me5.jpg'
import me6 from './img/me6.jpg'
import me7 from './img/me7.jpg'
import me8 from './img/me8.jpg'
import me9 from './img/me9.jpg'
import me10 from './img/me10.jpg'
import me11 from './img/me11.jpg'
import me12 from './img/me12.jpg'
import me13 from './img/me13.jpg'
import me14 from './img/me14.jpg'
import me15 from './img/me15.jpg'
import me16 from './img/me16.jpg'
import me17 from './img/me17.jpg'
import me18 from './img/me18.jpg'
import me19 from './img/me19.jpg'
import me20 from './img/me20.jpg'
import me21 from './img/me21.jpg'


function Mediacontentmore() {
  return (
    <>
    <div className="moremediacontainer">
    
    
    <div className="gallarysection3">
    <h1>Media Coverage</h1>
    <hr />

    <div className="gallarysec3cards">
        <div className="gallarysec3card">
            <img src={me1} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me2} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me3} alt="img" />
        </div>

    </div>
    <div className="gallarysec3cards">
        <div className="gallarysec3card">
            <img src={me4} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me5} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me6} alt="img" />
        </div>

    </div>
    <div className="gallarysec3cards">
        <div className="gallarysec3card">
            <img src={me7} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me8} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me9} alt="img" />
        </div>

    </div>
    <div className="gallarysec3cards">
        <div className="gallarysec3card">
            <img src={me10} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me11} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me12} alt="img" />
        </div>

    </div>
    <div className="gallarysec3cards">
        <div className="gallarysec3card">
            <img src={me13} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me14} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me15} alt="img" />
        </div>

    </div>
    <div className="gallarysec3cards">
        <div className="gallarysec3card">
            <img src={me16} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me17} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me18} alt="img" />
        </div>

    </div>
    <div className="gallarysec3cards">
        <div className="gallarysec3card">
            <img src={me19} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me20} alt="img" />
        </div>
        <div className="gallarysec3card">
            <img src={me21} alt="img" />
        </div>

    </div>
   
   

</div>

    
    
    </div>
        </>
  )
}

export default Mediacontentmore
