import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Homesec1 from './components/Homesec1';
import HomeOurMission from './components/HomeOurMission';
import HomeOurProject from './components/HomeOurProject';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Home1 from './components/Home1';
import { Route, Routes } from 'react-router-dom';
import About from './components/About';
import OurCauses from './components/OurCauses';
import Team from './components/Team';
import Sidebar from './components/Sidebar';
import Backdrop from './components/Backdrop';
import { useState } from 'react';
import Gallery from './components/Gallery';
import Mediacontentmore from './components/Mediacontentmore';



function App() {


      const [showModal, setModel] = useState(false);

      const closeModel = ()=>setModel(false);


  const [sidebar, setsidebar] = useState(false);

  const toggleSidebar = ()=>{
    setsidebar((prev)=>!prev)

  }



  return (
    <>

    <Navbar openSidebar={toggleSidebar} />
    <Sidebar sidebar={sidebar} closeback = {toggleSidebar} />
    <Backdrop sidebar={sidebar} closeback = {toggleSidebar} />

    <Routes>
    <Route path='/' element={<Home1/>}>
    
          </Route>
    
    <Route path='/contact' element={<Contact/>}>
    
          </Route>
    <Route path='/about' element={<About/>}>
    
          </Route>
    <Route path='/causes' element={<OurCauses/>}>
    
          </Route>
    <Route path='/team' element={<Team/>}>
    
          </Route>
    <Route path='/gallery' element={<Gallery/>}>
    
          </Route>
    <Route path='/mediamore' element={<Mediacontentmore/>}>
    
          </Route>
    
    </Routes>

    
    <Footer/>

    
      
    </>
  );
}

export default App;
